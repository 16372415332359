// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import dataTables from '@src/views/store/reducer'
import vehicleState from '@src/views/Vehicles/redux/reducer'
import servicesTypesState from '@src/views/ServicesTypes/redux/reducer'
import companyState from '@src/views/Companies/redux/reducer'
import companyTypesState from '@src/views/CompanyTypes/redux/reducer'
import branchState from '@src/views/Branches/redux/reducer'
import userState from '@src/views/Users/redux/reducer'
import orderState from '@src/views/OrderManagement/redux/reducer'
import disputeState from '@src/views/DisputeManagement/redux/reducer'
import roleState from '@src/views/RoleManagement/redux/reducer'
import permissionState from '@src/views/PermissionManagement/redux/reducer'
import assignPermissionState from '@src/views/AssignPermissionManagement/redux/reducer'
import paypalState from '@src/views/AccountConfigration/redux/reducer'
import serviceType from '@src/views/UserService/redux/reducer'
import callBackState from '@src/views/CallBack/redux/reducer'
import ctaState from '@src/views/Cta/redux/reducer'
// import languages from '@src/views/UserService/redux/reducer'
//import stripeState from '@src/views/AccountConfigration/redux/reducer'


const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  dataTables,
  vehicleState,
  servicesTypesState,
  companyState,
  companyTypesState,
  branchState,
  userState,
  orderState,
  disputeState,
  roleState,
  permissionState,
  assignPermissionState,
  paypalState,
  serviceType,
  callBackState,
  ctaState
  // languages
//  stripeState
})

export default rootReducer
