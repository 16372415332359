// ** Initial State
const initialState = {
    totalVehicles: 0,
    vehicleData: [],
    vehicleAlertMessage: undefined,
    vehicleAlertColor: undefined
}

const VehicleReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_VEHICLE_DATA':

      return {
        ...state,
        vehicleData: action.vehicleData,
        totalVehicles: action.totalVehicles
      }
      case 'SET_VEHICLE_FLASH_MESSAGE':
        return {
            ...state,
            vehicleAlertMessage: action.vehicleAlertMessage,
            vehicleAlertColor: action.vehicleAlertColor
        }
    case 'ERASE_VEHICLE_DATA':
        return {
          }
    default:
      return state
  }
}

export default VehicleReducer
