// ** Initial State
const initialState = {
    totalAssignPermission: 0,
    assignPermissionData: [],
    AssignPermissionAlertMessage: undefined,
    AssignPermissionAlertColor: undefined
}

const AssignPermissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ASSIGNPERMISSION_DATA':

      return {
        ...state,
        assignPermissionData: action.assignPermissionData,
        totalAssignPermission: action.totalAssignPermission
      }
    case 'SET_PERMISSION_FLASH_MESSAGE':
        return {
            ...state,
            AssignPermissionAlertMessage: action.AssignPermissionAlertMessage,
            AssignPermissionAlertColor: action.AssignPermissionAlertColor
        }
    case 'ERASE_PERMISSION_DATA':
        return {
          }
    default:
      return state
  }
}

export default AssignPermissionReducer
