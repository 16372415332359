// ** Initial State
const initialState = {
    totalCompanies: 0,
    companyTypeData: [],
    CompanyTypeAlertMessage: undefined,
    CompanyTypeAlertColor: undefined
}

const CompanyTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_COMPANYTYPE_DATA':

      return {
        ...state,
        companyTypeData: action.companyTypeData,
        totalCompany: action.totalCompany
      }
    case 'SET_CompanyTYPE_FLASH_MESSAGE':
        return {
            ...state,
            CompanyTypeAlertMessage: action.CompanyTypeAlertMessage,
            CompanyTypeAlertColor: action.CompanyTypeAlertColor
        }
    case 'ERASE_COMPANYTYPE_DATA':
        return {
          }
    default:
      return state
  }
}

export default CompanyTypeReducer
