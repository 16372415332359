// ** Initial State
const initialState = {
    totalDisputes: 0,
    disputeData: [],
    disputeAlertMessage: undefined,
    disputeAlertColor: undefined
}

const DisputeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DISPUTE_DATA':

      return {
        ...state,
        disputeData: action.disputeData,
        totalDisputes: action.totalDisputes
      }
      case 'SET_DISPUTE_FLASH_MESSAGE':
        return {
            ...state,
            disputeAlertMessage: action.disputeAlertMessage,
            disputeAlertColor: action.disputeAlertColor
        }
    case 'ERASE_DISPUTE_DATA':
        return {
          }
    default:
      return state
  }
}
export default DisputeReducer