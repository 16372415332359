// ** Initial State
const initialState = {
    totalUsers: 0,
    userData: [],
    userServiceTakerData:[],
    userServiceProviderData:[],
    userAlertMessage: undefined,
    userAlertColor: undefined
}

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USER_DATA':
      return {
        ...state,
        userData: action.userData,
        totalUsers: action.totalUsers
      }
      case 'SET_USER_FLASH_MESSAGE':
        return {
            ...state,
            userAlertMessage: action.userAlertMessage,
            userAlertColor: action.userAlertColor
        }
    case 'ERASE_USER_DATA':
        return {
          }
    
    case 'GET_USER_SERVICE_TAKER_DATA':
      return {
        ...state,
        userServiceTakerData: action.userServiceTakerData,
        totalUsers: action.totalUsers
      }
    case 'SET_USER_SERVICE_TAKER_FLASH_MESSAGE':
        return {
            ...state,
            userServiceTakerAlertMessage: action.userServiceTakerAlertMessage,
            userServiceTakerAlertColor: action.userServiceTakerAlertColor
        }
    case 'ERASE_USER_SERVICE_TAKER_DATA':
        return {
      }

    case 'GET_USER_SERVICE_PROVIDER_DATA':
        return {
          ...state,
          userServiceProviderData: action.userServiceProviderData,
          totalUsers: action.totalUsers
        }
    case 'SET_USER_SERVICE_PROVIDER_FLASH_MESSAGE':
          return {
              ...state,
              userServiceProviderAlertMessage: action.userServiceProviderAlertMessage,
              userServiceProviderAlertColor: action.userServiceProviderAlertColor
          }
    case 'ERASE_USER_SERVICE_PROVIDER_DATA':
          return {
        }

    default:
      return state
  }
}

export default UserReducer
